<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="contents-box">
        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">사이트/센터 선택 <span class="icon_require">필수항목</span></div>
            <div style="margin-right: 5px;">
              <DxSelectBox
                  v-model="formData.siteId"
                  placeholder="사이트 선택"
                  :items="getSiteList"
                  display-expr="siteNm"
                  value-expr="id"
                  :show-clear-button="config.showClearButton"
                  :styling-mode="stylingMode"
                  :width="150"
              >
                <DxValidator>
                  <DxRequiredRule message="사이트는 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </div>
            <div>
              <DxSelectBox
                  v-model="formData.tenantId"
                  placeholder="센터 선택"
                  :items="getTenantList"
                  display-expr="tenantNm"
                  value-expr="id"
                  :show-clear-button="config.showClearButton"
                  :styling-mode="stylingMode"
                  :width="150"
              >
                <DxValidator>
                  <DxRequiredRule message="센터는 필수입니다."/>
                </DxValidator>
              </DxSelectBox>
            </div>
          </div>
        </div>

        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">스킬그룹명 <span class="icon_require">필수항목</span></div>
            <div class="conts-input">
              <DxTextBox
                  v-model="formData.sklGroupNm"
                  :max-length="limitNumberTexts.maxLengths.sklGroupNm"
                  :styling-mode="stylingMode"
                  class="mar_ri10"
                  :width="255"
                  @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'sklGroupNm')"
              >
                <DxValidator>
                  <DxRequiredRule message="스킬그룹명은 필수입니다."/>
                </DxValidator>
              </DxTextBox>
              {{limitNumberTexts.textLengths.sklGroupNm ? limitNumberTexts.textLengths.sklGroupNm : (formData.sklGroupNm ? formData.sklGroupNm.length : 0)}}/{{ limitNumberTexts.maxLengths.sklGroupNm }}자
            </div>
          </div>
        </div>

        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">순서</div>
            <div class="conts-input">
              <DxNumberBox
                  v-model="formData.sklGroupOrd"
                  :min="1"
                  :max="getMaxOrd"
                  :show-spin-buttons="true"
                  :width="255"
                  :styling-mode="this.stylingMode"
              />
            </div>
          </div>
        </div>

        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">사용여부</div>
            <div class="conts-input" style="margin-left: 10px;">
              <DxSwitch
                  @value-changed="viewCdValueChange($event)"
                  :value="getViewCd"
                  switched-off-text="OFF"
                  switched-on-text="ON"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import { DxNumberBox } from 'devextreme-vue/number-box';
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';
import {DxSelectBox} from 'devextreme-vue/select-box';
import DxSwitch from "devextreme-vue/switch";

export default {
  components: {
    DxSwitch,
    DxNumberBox,
    DxTextBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    DxSelectBox
  },
  props: {
    modalContent: Object,    // Template Object
    maxOrd: Number,
    maxId: Number,
    method: String,             // Template Method ex) INSERT, UDPATE
  },
  watch: {},
  data() {
    return {
      config: {
        showClearButton: true,
        api: {
          sklGroupInsert: 'SKL_GROUP_LIST_INSERT',
          sklGroupUpdate: 'SKL_GROUP_LIST_UPDATE',
        },
      },
      stylingMode: 'outlined',    //[outlined, filled, underlined]
      formData: {             // Add/Update 시 사용할 객체
        id: null,
        site: {},
        tenant: {},
        sklGroupNm: null,
		sklGroupOrd: null,
        viewCd: 1
      },
      formFormat: {
        sklGroupNmSize: 30,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          sklGroupNm: 30,
        },
      },
    }
  },
  computed: {
    /** @description: 사이트 store 정보 가져오기 */
    getSiteList() {
      return this.$store.getters.getSiteList.filter(d => Number(d.viewCd) === this.$_getUsedCode.id);
    },
    /** @description: 테넌트 store 정보 가져오기 */
    getTenantList() {
      return this.$store.getters.getTenantList.filter(d => Number(d.viewCd) === this.$_getUsedCode.id);
    },
    getMaxOrd() {
      if(this.method === 'INSERT'){
        return this.maxOrd + 1;
      }else if (this.method === 'UPDATE') {
        return this.maxOrd;
      }
    },
    getViewCd() {
      return this.formData.viewCd === 1 ? true : false;
    },
  },
  methods: {
    /**
     * @description : 메서드 타입에 따른 API actionNm 반환
     * @param : methodType
     */
    getAPIActionNmByMethod(type) {
      let url = '';
      switch (type) {
        case "INSERT" :
          url = this.config.api.sklGroupInsert;
          break;
        case "UPDATE" :
          url = this.config.api.sklGroupUpdate;
          break;
      }
      return url;
    },
    // 모달 객체 초기화
    initModal() {
      Object.keys(this.formData).forEach(key => this.formData[key] = '');
    },
    viewCdValueChange(e) {
      if (e.value)
        this.formData.viewCd = 1
      else
        this.formData.viewCd = 2
    },
    /** @description : 라이프사이클 creaed시 호출되는 메서드 */
    createdData() {
      if (this.method === 'INSERT') {           // Insert 일경우 객체 속성 값 초기화
        this.initModal();
        this.formData.regId = this.$store.getters.getLoginId;
        this.formData.viewCd = 1;
        this.formData.sklGroupOrd = this.maxOrd + 1;
      } else if (this.method === 'UPDATE') {     // update 일경우 객체 속성 값 매칭
        this.formData = this.$_commonlib.cloneObj(this.modalContent);
        this.formData.editId = this.$store.getters.getLoginId;
      }
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
      this.$_eventbus.$on('ModalSklGroup:onSaveData', async (e, resolve) => {
        if (!e.validationGroup.validate().isValid) {
          return false;
        }

        if (await this.$_Confirm('스킬그룹 정보를 저장 하시겠습니까?')) {
			// INSERT 외에는 모두 UPDATE API
			let data = {
				data: [{
					id: this.formData.id,
					siteId: this.formData.siteId,
					tenantId: this.formData.tenantId,
					sklGroupNm: this.formData.sklGroupNm,
					sklGroupOrd: this.formData.sklGroupOrd,
					viewCd: this.formData.viewCd,
				}]
			};

			let params = {};
			if(this.method === 'INSERT' && this.maxOrd + 1 !== data.data[0].sklGroupOrd) {
				params = { isReordFl: true };
			}

			if(data.data[0].id === "" || data.data[0].id === null) {
				data.data[0].id = this.maxId + 1;
			}

			let url = this.getAPIActionNmByMethod(this.method);
			let payload = {
				actionname: url,
				data: {...data, ...params},
				loading: true,
			}

			let res = await this.CALL_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === "success") {
					if (this.$_Toast('스킬그룹 정보가 저장되었습니다.')) {
						resolve(200);
					}
				} else {
					this.$_Msg('작업 실패');
				}
			}
			}
      });
    },
    /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
    beforeDestroyedData() {
      this.$_eventbus.$off('ModalSklGroup:onSaveData');
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  beforeDestroy() {
    this.beforeDestroyedData();
  },
}
</script>

<style scoped>
.contents-box {
  width: 100%;
  padding: 20px 10px;
  background-color: #FFF;
}

.conts-row:not(:last-child) {
  margin-bottom: 20px;
}

.conts-row .conts-box {
  display: inline-block;
}

.conts-row .conts-box > div {
  display: inline-block;
}

.conts-row .conts-box .conts-title {
  width: 120px;
  display: inline-block;
  vertical-align: top;
}

.conts-row .conts-box .conts-input {
  display: -webkit-inline-box;
}

.conts-row .conts-box .conts-input .limitLength-box {
  margin-left: 5px;
  display: inline-block;
  font-size: 0.9em;
}
</style>